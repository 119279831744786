import Head from 'next/head';
import EntryPage from "/components/modules/landingpage/EntryPage";
import HelpButton from "/components/shared/buttons/HelpButton";
import Offers from "/components/modules/landingpage/Offers";
import {fetchProductions} from '/api/productions';
import InformationPart from "/components/modules/landingpage/InformationPart";
import ExploreSteps from "/components/modules/landingpage/ExploreSteps";
import CurrentShows from "/components/modules/landingpage/CurrentShows";


export default function LandingPage() {
    return (
        <>
            <Head>
                <meta name="viewport"
                      content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
            </Head>
            <main>
                <div className="relative w-screen">
                    <EntryPage/>
                    <CurrentShows/>
                    <InformationPart/>
                    <ExploreSteps/>
                    <Offers/>
                    <div className="fixed bottom-[44px] left-[48px] hidden sm:block z-50 ">
                        <HelpButton/>
                    </div>
                </div>
            </main>
        </>
    );
}

export async function getStaticProps(context) {
    const productions = await fetchProductions();
    return {
        props: {
            productions
        },
        revalidate: 60
    };
}

