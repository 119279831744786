import React from 'react';

const GoldButton = ({children, icon, onClick, style}) => {
    return (
        <button
            className={`bg-gold text-white uppercase flex justify-center items-center cursor-pointer w-max ${style}`}
            onClick={onClick}>
            {icon ? (
                <div>
                    {icon}
                </div>
            ) : null}
            <h4>{children}</h4>

        </button>
    );
};

export default GoldButton;
